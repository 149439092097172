import httpCommon from '~/api/httpCommon/httpCommon.js';

/**
 * Fetches specific news item based on its ID and locale.
 * Sends a GET request to fetch news details.
 * @param {string|number} id - The ID of the news item.
 * @param {string} locale - The locale code to specify the language for the request.
 * @returns {Promise<any>} A promise that resolves to the news item data.
 */
export function getNews(id, locale) {
    return httpCommon()
        .get(`news/${id}`, {
            headers: {
                locale: locale,
            },
        })
        .then(res => res.data);
}
/**
 * Fetches a list of news items based on specified parameters and locale.
 * Sends a GET request with query parameters to fetch a list of news items.
 * @param {object} params - Query parameters for the news list request.
 * @param {string} locale - The locale code to specify the language for the request.
 * @returns {Promise<any>} A promise that resolves to the list of news items.
 */
export function getNewsWithParams(params, locale) {
    return httpCommon()
        .get(`news-list`, {
            params,
            headers: {
                locale,
            },
        })
        .then(res => res.data);
}

/**
 * Fetches translation data for news items based on the specified locale.
 * Sends a GET request to fetch translation parameters for news items.
 * @param {string} locale - The locale code to specify the language for the request.
 * @returns {Promise<any>} A promise that resolves to the translation data for news items.
 */
export function getTranslate(locale) {
    return httpCommon()
        .get(`news-params`, {
            headers: {
                locale,
            },
        })
        .then(res => res.data);
}
